.App {
  text-align: center;
  margin-top: 60px;
}

.ant-upload-drag-icon {
  font-size: 50px;
  color: #40a9ff;
}

.borderless-table .ant-table,
.borderless-table .ant-table-thead > tr > th,
.borderless-table .ant-table-cell {
  border: none;
  font-size: 12px;
}

.borderless-table .ant-table-cell,
.borderless-table .ant-table-thead > tr > th {
  padding: 20px 15px;
}

.filename-span {
  max-width: 200px; /* Adjust as needed */
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-table thead tr th {
  text-align: center;  
}

.ant-table-thead > tr > th {
  font-size: 12px; /* Adjust the font size as needed */
}

